/**
 * Under minimum banners should be error red in checkout but only a warning (yellow/orange) in menu
 */
import ROUTES, { SHOPPING_PATHS } from 'helpers/routes'

import StyleableBanner from './styleable-banner'
import { ERROR, ErrorBannerTypes, WARNING } from './types'

// @TODO: route should be a required prop and/or we need a default for type when route is not present
export type UnderMinBannerProps = {
  cartMinimum: number | string
  route?: string
  inCartDrawer?: boolean
}

export default function UnderMinimum(props: UnderMinBannerProps): JSX.Element {
  const { cartMinimum, route, inCartDrawer } = props

  let type: ErrorBannerTypes

  if (route === ROUTES.CHECKOUT) {
    type = ERROR
  } else if (SHOPPING_PATHS.some((path) => route?.includes(path))) {
    type = WARNING
  }

  const message = `Your cart is under the $${cartMinimum} minimum.`

  return <StyleableBanner data-sentry-unmask message={message} bannerType={type} inCartDrawer={inCartDrawer} />
}
