import { memo } from 'react'

import { NextRouter } from 'next/router'

import { STATUS_CODE_DELIVERY_DECREASED, STATUS_CODE_DELIVERY_INCREASED } from '@helpers/constants'
import { dispatchIdClickoutEvent, isInAppView } from 'helpers/is-mobile'
import ROUTES from 'helpers/routes'
import { alertTypes } from 'redux/alert/config/types'

import alertText from './alert-text'
import ClosingSoon from './closing-soon'
import Credit from './credit'
import ExpiredId from './expired-id'
import StoreClosedScheduledDeliveryOnBanner from './store-closed-scheduled-delivery-on'
import StyleableBanner from './styleable-banner'
import { BannerTypes, ERROR, INFO, SUCCESS } from './types'
import UnderMinimum from './under-minimum'

type AlertStyles = {
  [key: number]: string
}

export const SIFT_CARD_DECLINED_ERROR = 'Order declined, our heuristics indicate a high possibility of fraud.'

export type BannerProps = {
  alertKey: string
  authError: string
  cartMinimum: number
  changePasswordError: string
  checkoutError: string
  checkoutMessage: string
  checkoutMessageStatusCode: number
  closedMessage: string
  closeTime: string
  creditAmount: number
  depotOpeningSoon: boolean
  hasPhoneNumber: boolean
  inCartDrawer: boolean
  openingSoonLocation: string
  priceInfo: { subtotal?: number }
  relative: boolean
  route: string
  router: NextRouter
  toggleReferralModal: () => void
  verificationProcessErrors: { generic?: any; upload?: any }
}

function Banner(props: BannerProps): JSX.Element {
  const {
    alertKey,
    authError,
    cartMinimum,
    changePasswordError,
    checkoutError,
    checkoutMessage,
    checkoutMessageStatusCode,
    closedMessage,
    closeTime,
    creditAmount,
    depotOpeningSoon,
    inCartDrawer,
    openingSoonLocation,
    route,
    router,
    toggleReferralModal,
    verificationProcessErrors
  } = props

  let banner: JSX.Element

  const navigateToVerify = () => {
    // If we are in the webview app, don't redirect to verify page, just dispatch an event (handling in app)
    if (isInAppView) {
      dispatchIdClickoutEvent()
      return
    }
    router.push(ROUTES.VERIFY)
  }

  const navigateToLogin = () => {
    router.push(ROUTES.LOGIN)
  }

  switch (alertKey) {
    case alertTypes.CHECKOUT: {
      let errorMessage = checkoutError || ''

      if (errorMessage) {
        if (errorMessage?.match(/PreAuth_Error/) || errorMessage?.match(/Prauth/)) {
          errorMessage = 'We were unable to process your card. Please add a different card and try again.'
        } else if (errorMessage === SIFT_CARD_DECLINED_ERROR) {
          // friendlier copy for sift science declined cards
          errorMessage = "We're having issues processing your card. But don't worry, you can always pay with cash."
        } else if (errorMessage?.match(/Invalid data in body/)) {
          errorMessage = 'Please confirm your credit card details and try again.'
        }
      }

      banner = <StyleableBanner message={errorMessage} bannerType={ERROR} inCartDrawer={inCartDrawer} />
      break
    }

    case alertTypes.CHECKOUT_MESSAGE: {
      const alertStyles: AlertStyles = {
        [STATUS_CODE_DELIVERY_INCREASED]: ERROR,
        [STATUS_CODE_DELIVERY_DECREASED]: SUCCESS
      }

      banner = (
        <StyleableBanner
          bannerType={alertStyles[checkoutMessageStatusCode] as BannerTypes}
          message={checkoutMessage}
          inCartDrawer={inCartDrawer}
        />
      )
      break
    }

    case alertTypes.CREDIT:
      banner = (
        <Credit toggleReferralModal={toggleReferralModal} creditAmount={creditAmount} inCartDrawer={inCartDrawer} />
      )
      break

    case alertTypes.EXISTING_EMAIL_ADDRESS:
      banner = (
        <StyleableBanner
          onClick={() => router.push(`${ROUTES.MENU}?help=true&form=contact`)}
          message="This email is associated with another Eaze account."
          underlinedMessage="Please contact support for additional help."
          bannerType={ERROR}
          inCartDrawer={inCartDrawer}
        />
      )
      break

    case alertTypes.EXISTING_PHONE_NUMBER:
      banner = (
        <StyleableBanner
          onClick={() => router.push(`${ROUTES.MENU}?help=true&form=contact`)}
          message="This phone number is associated with another Eaze account."
          underlinedMessage="Please contact support for additional help."
          bannerType={ERROR}
          inCartDrawer={inCartDrawer}
        />
      )
      break

    case alertTypes.EXPIRED_REC:
      banner = (
        <StyleableBanner
          onClick={() => router.push(ROUTES.PROFILE)}
          message="Your recommendation has expired!"
          underlinedMessage="Update your account."
          bannerType={ERROR}
          inCartDrawer={inCartDrawer}
        />
      )
      break

    case alertTypes.ID_INVALID_IN_AREA:
      banner = (
        <StyleableBanner
          message="Your ID type is not valid for delivery in this location. Change your location or"
          underlinedMessage="upload another ID."
          onClick={navigateToVerify}
          bannerType={route === ROUTES.CHECKOUT ? ERROR : INFO}
          inCartDrawer={inCartDrawer}
        />
      )
      break

    case alertTypes.HAS_LINKED_ACCOUNTS:
      banner = (
        <StyleableBanner
          message="You already have an Eaze account."
          underlinedMessage="Log in."
          onClick={navigateToLogin}
          bannerType={route === ROUTES.CHECKOUT ? ERROR : INFO}
          inCartDrawer={inCartDrawer}
        />
      )
      break

    case alertTypes.ID_DECLINED:
      banner = (
        <StyleableBanner
          message="There was an issue with your ID."
          underlinedMessage="Please try uploading it again."
          onClick={navigateToVerify}
          bannerType={route === ROUTES.CHECKOUT ? ERROR : INFO}
          inCartDrawer={inCartDrawer}
        />
      )
      break

    case alertTypes.ID_REQUIRED:
      banner = (
        <StyleableBanner
          message="We need to see your ID before you can place an order on Eaze."
          underlinedMessage="Complete your verification."
          onClick={navigateToVerify}
          bannerType={route === ROUTES.CHECKOUT ? ERROR : INFO}
          inCartDrawer={inCartDrawer}
        />
      )
      break

    case alertTypes.INVALID_STREET_ADDRESS:
      banner = (
        <StyleableBanner
          message={alertText[alertKey]}
          bannerType={route === ROUTES.CHECKOUT ? ERROR : INFO}
          inCartDrawer={inCartDrawer}
        />
      )
      break

    case alertTypes.LOGIN_OR_SIGNUP_ERROR:
      banner = changePasswordError?.includes('password reset session') ? (
        <StyleableBanner
          message={changePasswordError}
          underlinedMessage="Please reset your password again."
          onClick={() => router.push(ROUTES.RESET_PASSWORD)}
          bannerType={ERROR}
        />
      ) : (
        <StyleableBanner message={authError || changePasswordError} bannerType={ERROR} />
      )
      break

    case alertTypes.PHONE_VERIFICATION_INCOMPLETE:
      banner = (
        <StyleableBanner
          message="We need to verify your phone number before you can place an order on Eaze."
          underlinedMessage="Verify phone number."
          onClick={() => router.push(ROUTES.VERIFY)}
          bannerType={route === ROUTES.CHECKOUT ? ERROR : INFO}
          inCartDrawer={inCartDrawer}
        />
      )
      break

    // This is an unused alert - triggering /legal purchase limit/ error uses the CHECKOUT alert type
    case alertTypes.AT_DAILY_LIMIT:
      banner = <StyleableBanner message={checkoutError} bannerType={ERROR} inCartDrawer={inCartDrawer} />
      break

    case alertTypes.STORE_CLOSED: {
      banner = depotOpeningSoon ? (
        <StyleableBanner
          onClick={() =>
            router.push(
              `${ROUTES.INVITE}/eazefirsttime?itm_source=menu&itm_medium=banner&itm_campaign=30off&itm_term=2021&itm_content=copy`
            )
          }
          message={`Hey ${openingSoonLocation}, we're coming soon!`}
          underlinedMessage="Sign up now to receive early access to exclusive deals and updates."
          bannerType={INFO}
          inCartDrawer={inCartDrawer}
        />
      ) : (
        <StyleableBanner
          message={closedMessage ? `We're currently closed. ${closedMessage}` : "We're currently closed."}
          bannerType={INFO}
          inCartDrawer={inCartDrawer}
        />
      )
      break
    }

    case alertTypes.STORE_CLOSING_SOON:
      banner = <ClosingSoon closeTime={closeTime} inCartDrawer={inCartDrawer} />
      break

    case alertTypes.STORE_CLOSED_SCHEDULED_DELIVERY_ON:
      banner = <StoreClosedScheduledDeliveryOnBanner inCartDrawer={inCartDrawer} />
      break

    case alertTypes.UNDER_MINIMUM:
      banner = <UnderMinimum route={route} cartMinimum={cartMinimum} inCartDrawer={inCartDrawer} />
      break

    case alertTypes.VERIFICATION_PENDING:
      banner = (
        <StyleableBanner
          message={alertText[alertKey]}
          bannerType={route === ROUTES.CHECKOUT ? ERROR : INFO}
          inCartDrawer={inCartDrawer}
        />
      )
      break

    case alertTypes.VERIFICATION_PROCESS_ERROR:
      banner = (
        <StyleableBanner message={verificationProcessErrors.generic} bannerType={ERROR} inCartDrawer={inCartDrawer} />
      )
      break

    case alertTypes.EXPIRED_ID:
      banner = <ExpiredId inCartDrawer={inCartDrawer} />
      break

    case alertTypes.DISCOUNTED_DELIVERY_FEE:
    case alertTypes.DISCOUNTED_DELIVERY_BYPASS:
    case alertTypes.FREE_DELIVERY:
      banner = <StyleableBanner message={alertText[alertKey]} bannerType={SUCCESS} inCartDrawer={inCartDrawer} />
      break

    case alertTypes.ID_PENDING:
    case alertTypes.INVALID_LOGIN:
    case alertTypes.NO_DRIVERS:
    case alertTypes.PRODUCTS_UNAVAILABLE:
      banner = <StyleableBanner message={alertText[alertKey]} bannerType={ERROR} inCartDrawer={inCartDrawer} />
      break

    case alertTypes.VERIFICATION_RESEND_SUCCESSFUL:
    case alertTypes.LOCATION_OUT_OF_SERVICE:
    default:
      banner = <StyleableBanner message={alertText[alertKey] as string} bannerType={INFO} inCartDrawer={inCartDrawer} />
  }

  return banner
}

export default memo(Banner)
