/**
 * Closing Soon banner
 */

import { parseClosingTime } from '@helpers/date'

import StyleableBanner from './styleable-banner'
import { WARNING } from './types'

type ClosingSoonBannerProps = {
  closeTime: string | Date
  inCartDrawer?: boolean
}

export default function ClosingSoon(props: ClosingSoonBannerProps): JSX.Element {
  const { closeTime } = props
  const timeString = parseClosingTime(closeTime)
  const message = `Eaze is closing soon. Please place your order before ${timeString}.`

  return <StyleableBanner message={message} bannerType={WARNING} inCartDrawer={props.inCartDrawer} />
}
