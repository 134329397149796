import { numberToPrice } from '@helpers/price-helpers'

import StyleableBanner from './styleable-banner'
import { SUCCESS } from './types'

export type CreditBannerProps = {
  creditAmount: number
  toggleReferralModal: () => void
  inCartDrawer: boolean
}

export default function Credit(props: CreditBannerProps): JSX.Element {
  const { creditAmount, toggleReferralModal, inCartDrawer } = props
  const message = `You have ${numberToPrice(creditAmount)} available for this order! To earn more credit,`

  return (
    <StyleableBanner
      onClick={toggleReferralModal}
      message={message}
      underlinedMessage="invite your friends."
      bannerType={SUCCESS}
      inCartDrawer={inCartDrawer}
    />
  )
}
