import { Alerts } from '@/redux/alert/config/types'
import {
  DELIVERY_FEE_MESSAGING,
  DISCOUNTED_DELIVERY_BYPASS_KEY,
  DISCOUNTED_DELIVERY_FEE_KEY
} from '@helpers/order-summary/message-formatters'

const alertText: { [K in Alerts]?: string } = {
  CANCELED: 'Your order was canceled by the driver. Please try again!',
  DISCOUNTED_DELIVERY_BYPASS: DELIVERY_FEE_MESSAGING[DISCOUNTED_DELIVERY_BYPASS_KEY],
  DISCOUNTED_DELIVERY_FEE: DELIVERY_FEE_MESSAGING[DISCOUNTED_DELIVERY_FEE_KEY],
  ID_PENDING:
    "We're currently experiencing an outage in our ID verification systems. We'll message you once the issue is resolved.",
  INVALID_LOGIN: 'Your email and password combination did not match an existing user. Please try again.',
  INVALID_STREET_ADDRESS: 'Please enter a full street address for delivery.',
  FREE_DELIVERY: 'Free delivery for all of August! Get some summer savings!',
  LOCATION: "We aren't in your area yet, but we're always adding new areas. Check out our sample menu in the meantime.",
  LOCATION_OUT_OF_SERVICE: "We aren't available in your area.",
  NO_DRIVERS: 'Sorry, no drivers are currently available to fulfill orders.',
  PRODUCTS_UNAVAILABLE:
    'Sorry, there are no drivers who are able to fulfill your entire order. Please try a different combination of products.',
  VERIFICATION_PENDING:
    "We're reviewing your information. You'll be able to order to this address once you're verified.",
  VERIFICATION_RESEND_SUCCESSFUL: 'Verification text was resent.'
}

export default alertText
