import { useDispatch } from 'react-redux'

import { track } from 'analytics'
import { dispatchIdClickoutEvent, isInAppView } from 'helpers/is-mobile'
import { togglePersonaModal } from 'redux/modal/actions'

import StyleableBanner from './styleable-banner'
import { WARNING } from './types'

export const EXPIRED_ID_MESSAGE = 'Your ID is expired.'
export const EXPIRED_ID_UNDERLINE_MESSAGE = 'Upload an unexpired ID.'

type ExpiredIdProps = {
  inCartDrawer?: boolean
}

function ExpiredId(props: ExpiredIdProps): JSX.Element {
  const { inCartDrawer } = props
  const dispatch = useDispatch()

  const handleOpeningModal = () => {
    if (isInAppView) {
      dispatchIdClickoutEvent()
      return // Don't want to double-count the event in analytics or open the broken modal (it would just show the overlay)
    }
    track('Signup.StateId.Upload')
    dispatch(togglePersonaModal())
  }

  return (
    <StyleableBanner
      bannerType={WARNING}
      message={EXPIRED_ID_MESSAGE}
      onClick={handleOpeningModal}
      underlinedMessage={EXPIRED_ID_UNDERLINE_MESSAGE}
      inCartDrawer={inCartDrawer}
    />
  )
}

export default ExpiredId
