import fecha from 'fecha'
import { useSelector } from 'react-redux'

import { getOpenTime } from '@/redux/location/selectors'

import StyleableBanner from './styleable-banner'
import { SUCCESS } from './types'

export default function StoreClosedScheduledDeliveryOnBanner(props: { inCartDrawer: boolean }) {
  const openTime = useSelector(getOpenTime)
  const message = `Schedule a delivery! We begin deliveries at ${fecha.format(openTime, 'h:mm A')}.`

  return <StyleableBanner message={message} bannerType={SUCCESS} inCartDrawer={props.inCartDrawer} />
}
