import { useEffect } from 'react'

import styled from '@emotion/styled'
import isEmptyObject from 'is-empty-object'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'

import alertConfig from '@/redux/alert/config'
import { alertTypes } from '@/redux/alert/config/types'
import { getBannerKeys, getClosedMessage, getVisibleBannerKey } from '@/redux/alert/selectors'
import { getAuthError } from '@/redux/auth/selectors'
import { getCheckoutError, getCheckoutMessage, getCheckoutMessageStatusCode } from '@/redux/checkout/selectors'
import {
  getCloseTime,
  getDepotMinimumOrder,
  getIsDepotOpeningSoon,
  getOpeningSoonLocation
} from '@/redux/location/selectors'
import { getCurrentPath } from '@/redux/misc-selectors/app'
import { toggleReferralModal } from '@/redux/modal/actions'
import { getCreditAmount, getIsPhonePresent, getPasswordChangeStatus } from '@/redux/profile/selectors'
import { getPriceInfo } from '@/redux/quote/selectors'
import { getVerificationProcessErrors } from '@/redux/verification/selectors'
import { breakpoint } from '@/theme'
import errorHandler from 'error-handler'

import Banner from './component'

import PropTypes, { InferProps } from 'prop-types'

export type BannerContainerProps = InferProps<typeof BannerContainer.propTypes>

export default function BannerContainer({ bannerKey, ignoreSelector, relative, inCartDrawer }: BannerContainerProps) {
  const router = useRouter()
  const dispatch = useDispatch()

  const bannerKeys = useSelector(getBannerKeys)
  const alertKey = bannerKey || (getVisibleBannerKey(bannerKeys, router.pathname, router.query) as string)

  const priceInfo = useSelector(getPriceInfo)
  const cartMinimum = useSelector(getDepotMinimumOrder)
  const changePasswordStatus = useSelector(getPasswordChangeStatus)

  useEffect(() => {
    if (alertKey === alertTypes.UNDER_MINIMUM && !isEmptyObject(priceInfo) && priceInfo.subtotal >= cartMinimum) {
      errorHandler(new Error(`cart_minimum_mismatch: cartMinimum: ${cartMinimum}, subTotal: ${priceInfo.subtotal}`))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const props = {
    alertKey,
    authError: useSelector(getAuthError),
    cartMinimum,
    changePasswordError: changePasswordStatus.err,
    checkoutError: useSelector(getCheckoutError),
    checkoutMessage: useSelector(getCheckoutMessage),
    checkoutMessageStatusCode: useSelector(getCheckoutMessageStatusCode),
    closedMessage: useSelector(getClosedMessage),
    closeTime: useSelector(getCloseTime),
    creditAmount: useSelector(getCreditAmount),
    depotOpeningSoon: useSelector(getIsDepotOpeningSoon),
    hasPhoneNumber: useSelector(getIsPhonePresent),
    inCartDrawer,
    openingSoonLocation: useSelector(getOpeningSoonLocation),
    priceInfo,
    relative,
    route: useSelector((state) => getCurrentPath(state, { ...router.query })),
    router: router,
    toggleReferralModal: () => dispatch(toggleReferralModal()),
    verificationProcessErrors: useSelector(getVerificationProcessErrors)
  }

  // Not all alertKeys trigger banners so we can have an alertKey but not have a banner to render
  // Use the alert config to check if the alertKey will render a banner
  // eslint-disable-next-line no-prototype-builtins
  if (!alertKey || !alertConfig[alertKey].hasOwnProperty('banner')) return null

  return (
    <BannerSpacerDiv inCartDrawer={inCartDrawer}>
      <Container relative={relative}>
        <Banner {...props} />
      </Container>
    </BannerSpacerDiv>
  )
}

BannerContainer.propTypes = {
  alertKey: PropTypes.string,
  bannerKey: PropTypes.string,
  ignoreSelector: PropTypes.bool,
  inCartDrawer: PropTypes.bool,
  priceInfo: PropTypes.shape({ subtotal: PropTypes.number }),
  relative: PropTypes.bool,
  cartMinimum: PropTypes.number
}

const BannerSpacerDiv = styled.div<{ inCartDrawer: boolean }>`
  height: ${({ inCartDrawer }) => (inCartDrawer ? 'auto' : '64px')};
  margin-top: ${({ inCartDrawer, theme }) => (inCartDrawer ? `${theme.spacing.medium}px` : '0')};
  margin-bottom: ${({ inCartDrawer, theme }) => (inCartDrawer ? `${theme.spacing.default}px` : '0')};
  @media (max-width: ${breakpoint.max.lg}px) {
    height: ${({ inCartDrawer }) => (inCartDrawer ? 'auto' : '56px')};
  }
`

const Container = styled.div<{ relative: any }>`
  z-index: 11;
  left: 0;
  right: 0;
  top: 0;
  line-height: 1;
  transform: ${({ relative }) => (relative ? 'none' : 'translateZ(0)')};
  box-shadow: ${({ relative }) => (relative ? 'none' : '0 0.7rem 1.4rem 0 rgba(0, 0, 0, 0.15)')};
  position: ${({ relative }) => (relative ? 'relative' : 'fixed')};
`
