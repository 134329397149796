import { memo } from 'react'

import styled from '@emotion/styled'

import { BannerTypes, getBannerBackgroundColor } from '@/stories/banner/types'
import { breakpoint } from '@/theme'

import { TEST_IDS } from './test/constants'

export type BannerProps = {
  bannerType?: BannerTypes
  message?: string | Error
  underlinedMessage?: string
  onClick?: () => void
  inCartDrawer?: boolean
}

function Banner(props: BannerProps): JSX.Element {
  let { message } = props
  const { bannerType, underlinedMessage, onClick, inCartDrawer } = props

  if (message instanceof Error) message = message.toString()

  if (!message) return null

  return (
    <StyledBanner
      data-sentry-unmask
      data-e2eid={TEST_IDS.COMPONENT}
      type={bannerType}
      onClick={onClick}
      role={onClick && 'button'}
      inCartDrawer={inCartDrawer}>
      {underlinedMessage && (
        <div>
          <span className={'sentry-unmask'}>{message}</span>
          <Link className={'sentry-unmask'}>{underlinedMessage}</Link>
        </div>
      )}
      {!underlinedMessage && message}
    </StyledBanner>
  )
}

const StyledBanner = styled.div<{ type: BannerTypes; onClick: () => void; inCartDrawer: boolean }>`
  // INFO and default banner type cases use a black bg, text needs to be white
  // We need to set white as default text and check if the type is ERROR, SUCCESS, or WARNING
  color: ${({ theme, type }) =>
    type === 'ERROR' || type === 'SUCCESS' || type === 'WARNING' ? theme.colors.text : theme.colors.textInverse};
  width: 100%;
  padding: 1rem;
  font-family: ${(props) => props.theme.typography.font.sim};
  font-size: ${(props) => props.theme.typography.size.desktop.body4}px;
  font-weight: 200;
  text-align: center;

  /* Menu Push down! */
  height: ${({ inCartDrawer }) => (inCartDrawer ? '33px' : '64px')};
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  background-color: ${({ type }) => getBannerBackgroundColor(type)};
  border-radius: 0;
  @media (max-width: ${breakpoint.max.lg}px) {
    height: ${({ inCartDrawer }) => (inCartDrawer ? '33px' : '56px')};
  }
`

const Link = styled.span`
  text-decoration: underline;
  padding: 0 0.5rem;
`

export default memo(Banner)
